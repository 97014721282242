<template>
  <div class="pa-5">
    <v-card elevation="0" class="pa-5">
      <div class="page-title-box d-flex align-items-center justify-content-between">
        <h4 class="mb-0">ค่ามือแพทย์</h4>
      </div>
      <v-card-title class="pt-0 px-0">
        <v-row>
          <v-col cols="12" sm="12" md="12" lg="4">
            <v-text-field
              v-model="filter_search"
              label="พิมพ์คำค้นหา เช่น ชื่อแพทย์"
              single-line
              hide-details
            >
              <v-icon
                slot="prepend"
                color="#182839"
              >
                mdi-magnify
              </v-icon>
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="2">
            <v-select
              :items="itemsCategory"
              v-model="select_filter_doctor"
              item-text="name"
              item-value="name"
              label="ชื่อแพทย์"
              placeholder="เลืือกแพทย์์"
              no-data-text="ยังไม่มีข้อมูล"
              single-line
              hide-details
            ></v-select>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <div class="d-flex justify-end align-end">
              <v-btn
                :loading="loader"
                :disabled="loader"
                color="blue-grey"
                class="white--text"
                @click="addnew()"
              >
                <v-icon
                  left
                  dark
                >
                  mdi-plus-circle
                </v-icon>
                เพิ่มแพทย์
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="data"
        :search="filter_search"
        :loading="loaddata"
        loading-text="กำลังโหลด..."
      >
      <template v-slot:item="{ item }">
        <tr>
          <td class="text-start">{{item.doctor_code}}</td>
          <td v-if="itemsNameFilterLG === 'nameEN'" class="text-start">{{`${item.doctor_fnameen} ${item.doctor_lnameen}`}}</td>
          <td v-else class="text-start">{{`${item.doctor_fnameth} ${item.doctor_lnameth}`}}</td>
          <td class="text-end">{{item.doctor_work_hour}}</td>
          <td class="text-end">{{item.doctor_work_wagehour}}</td>
          <td class="text-end">{{item.doctor_work_wagehour * item.doctor_work_hour}}</td>
          <!-- <td v-else class="text-center">{{item.doctor_nicknameth}}</td>
          <td class="text-center">{{moment(item.doctor_birthdate, "YYYYMMDD").locale('th').fromNow().replace(' ปีที่แล้ว', '')}}</td>
          <td class="text-end">{{formatCurrency(item.doctor_paid_cost)}}</td>
          <td class="text-end">{{formatCurrency(item.doctor_remain_cost)}}</td>
          <td class="text-center">
            <v-btn
              icon
              @click="viewCustomer(item)"
            >
              <v-icon>mdi-eye</v-icon>
            </v-btn>
          </td> -->
        </tr>
      </template>

      <template v-slot:no-data>
        <v-btn
          color="primary"
          @click="initData"
        >
          รีเซ็ต
        </v-btn>
      </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import swal from 'sweetalert'

export default {
  data () {
    return {
      loaddata: false,
      loadingSearchCustomer: false,
      loadingAddCustomer: false,
      cansave: true,
      filter_search: '',
      select_filter_search: '',
      showDialog: false,
      dataDialog: {},
      boolAddItems: false,
      boolViewItems: false,
      boolEditItems: false,
      boolDelItems: false,
      itemsProductTypeFilter: [],
      itemsProductTypeCreate: [],
      data: [{
        _id: '000',
        doctor_code: 'DT640015',
        doctor_fnameth: 'ชูใจ',
        doctor_lnameth: 'นามสมมติ',
        doctor_fnameen: 'Chojai',
        doctor_lnameen: 'Namsommud',
        doctor_nicknameth: 'กุ๊ก',
        doctor_nicknameen: 'Kook',
        doctor_birthdate: '1990-01-12',
        doctor_prepaid_cost: 0,
        doctor_paid_cost: 3000,
        doctor_remain_cost: 500,
        doctor_work_hour: 50,
        doctor_work_wagehour: 1500
      }, {
        _id: '001',
        doctor_code: 'DT640020',
        doctor_fnameth: 'สมใจ',
        doctor_lnameth: 'ใจดี',
        doctor_fnameen: 'Somjai',
        doctor_lnameen: 'Jaidee',
        doctor_nicknameth: 'หญิง',
        doctor_nicknameen: 'Ying',
        doctor_birthdate: '1960-04-19',
        doctor_prepaid_cost: 0,
        doctor_paid_cost: 50000,
        doctor_remain_cost: 0,
        doctor_work_hour: 50,
        doctor_work_wagehour: 1200
      }, {
        _id: '002',
        doctor_code: 'DT640035',
        doctor_fnameth: 'สมาน',
        doctor_lnameth: 'ภักดี',
        doctor_fnameen: 'Saman',
        doctor_lnameen: 'Phakdee',
        doctor_nicknameth: 'สมาน',
        doctor_nicknameen: 'Saman',
        doctor_birthdate: '1944-07-21',
        doctor_prepaid_cost: 0,
        doctor_paid_cost: 2000,
        doctor_remain_cost: 1000,
        doctor_work_hour: 50,
        doctor_work_wagehour: 1800
      }],
      lettersEN: /^[A-Za-z]+$/,
      filter_search_fname: '',
      search_fname: null,
      itemsNameFilterLG: 'nameTH',
      itemsFNameFilter: [{
        _id: '000',
        nameTH: 'ชูใจ',
        nameEN: 'Chojai'
      }, {
        _id: '001',
        nameTH: 'สมใจ',
        nameEN: 'Somjai'
      }, {
        _id: '002',
        nameTH: 'สมาน',
        nameEN: 'Saman'
      }],
      filter_search_lname: '',
      search_lname: null,
      itemsLNameFilter: [{
        _id: '000',
        nameTH: 'นามสมมติ',
        nameEN: 'Namsommud'
      }, {
        _id: '001',
        nameTH: 'ใจดี',
        nameEN: 'Jaidee'
      }, {
        _id: '002',
        nameTH: 'ภักดี',
        nameEN: 'Phakdee'
      }],
      filter_search_code: '',
      search_code: null,
      itemsCodeFilter: [{
        _id: '000',
        name: 'WG640015',
        value: 'WG640015'
      }, {
        _id: '001',
        name: 'WG640020',
        value: 'WG640020'
      }, {
        _id: '002',
        name: 'WG640035',
        value: 'WG640035'
      }],
      filter_search_phone: '',
      search_phone: null,
      itemsPhoneFilter: [{
        _id: '000',
        name: '0812345678',
        value: '0812345678'
      }, {
        _id: '001',
        name: '0888888888',
        value: '0888888888'
      }, {
        _id: '002',
        name: '0899999999',
        value: '0899999999'
      }]
    }
  },
  computed: {
    headers () {
      return [
        {
          text: 'รหัส',
          align: 'center',
          value: 'doctor_code',
          sortable: false
        }, {
          text: 'ชื่อ - นามสกุล',
          align: 'center',
          value: 'doctor_fnameth',
          sortable: false
        }, {
          text: 'จำนวนชั่วโมง',
          align: 'center',
          value: 'doctor_work_hour',
          sortable: false
        }, {
          text: 'ค่ามือแพทย์/ชั่วโมง',
          align: 'center',
          value: 'doctor_work_wagehour',
          sortable: false
        }, {
          text: 'ค่ามือแพทย์',
          align: 'center',
          value: 'doctor_work_hour',
          sortable: false
        }
      ]
    }
  },
  watch: {
    select_filter_search () {
      const self = this
      if (self.select_filter_search === 'ทั้งหมด') {
        self.filter_search = ''
      } else {
        self.filter_search = self.select_filter_search
      }
    },
    search_fname () {
      const self = this
      if (self.search_fname.match(self.lettersEN)) {
        self.itemsNameFilterLG = 'nameEN'
      } else {
        self.itemsNameFilterLG = 'nameTH'
      }
    },
    search_lname () {
      const self = this
      if (self.search_lname.match(self.lettersEN)) {
        self.itemsNameFilterLG = 'nameEN'
      } else {
        self.itemsNameFilterLG = 'nameTH'
      }
    }
  },
  methods: {
    formatCurrency (cost) {
      const formatter = new Intl.NumberFormat('th-TH', {
        style: 'currency',
        currency: 'THB',
        minimumFractionDigits: 2
      })
      return (formatter.format(cost)).replace('฿', '')
    },
    filterOnlyCapsText (value, search, item) {
      return value != null &&
        search != null &&
        typeof value === 'string' &&
        value.toString().toLocaleUpperCase().indexOf(search) !== -1
    },
    addItem () {
      const self = this
      self.boolAddItems = true
      self.showDialog = true
    },
    editItem (item) {
      const self = this
      self.dataDialog = item
      self.boolEditItems = true
      self.showDialog = true
    },
    deleteItem (item) {
      const self = this
      self.dataDialog = item
      self.boolDelItems = true
      self.showDialog = true
    },
    closeDialog () {
      const self = this
      self.boolAddItems = false
      self.boolViewItems = false
      self.boolEditItems = false
      self.boolDelItems = false
      self.showDialog = false
      self.dataDialog = {}
      self.cansave = true
    },
    async submitDialog () {
      const self = this
      if (self.boolAddItems) {
        if (self.dataDialog.product_group_name && self.dataDialog.product_type) {
          swal('สำเร็จ', 'เพิ่มข้อมูลลูกค้าสำเร็จ', 'success', {
            button: false,
            timer: 2000
          })
        } else {
          swal('คำเตือน', 'กรุณากรอกข้อมูลลูกค้าให้ครบถ้วน', 'warning', {
            button: false,
            timer: 3000
          })
        }
      } else if (self.boolEditItems) {
        if (self.dataDialog.product_group_name && self.dataDialog.product_type) {
          swal('สำเร็จ', 'แก้ไขข้อมูลลูกค้าสำเร็จ', 'success', {
            button: false,
            timer: 2000
          })
        } else {
          swal('คำเตือน', 'กรุณากรอกข้อมูลลูกค้าให้ครบถ้วน', 'warning', {
            button: false,
            timer: 3000
          })
        }
      } else if (self.boolDelItems) {
        swal('สำเร็จ', 'ลบข้อมูลลูกค้าสำเร็จ', 'success', {
          button: false,
          timer: 2000
        })
      } else {
        self.closeDialog()
      }
    },
    searchAllCustomer () {
      const self = this
      self.loadingSearchCustomer = true
      setTimeout(() => {
        self.loadingSearchCustomer = false
      }, 2000)
    },
    goAddNewCustomer () {
      const self = this
      const item = {
        firstname: self.search_fname || self.filter_search_fname,
        lastname: self.search_lname || self.filter_search_lname,
        phonenumber: self.search_phone || self.filter_search_phone
      }
      setTimeout(() => {
        self.$router.push({ name: 'CustomerCreateScreen', params: item })
      }, 200)
    },
    viewCustomer (item) {
      const self = this
      setTimeout(() => {
        self.$router.push({ name: 'CustomerViewScreen', params: { customerItems: item } })
      }, 200)
    }
  }
}
</script>

<style scoped>
@import '../../../../styles/Dashboard.css';
@import '../../../../styles/Customer.css';
</style>
